import {useParams} from "react-router-dom";
import {ShowFull} from "../components/ShowFull";

export function Show() {
    let { showId } = useParams();

    return (
        <div>
            <ShowFull showId={showId} highlights="true"/>
        </div>
    )
}
