import "../styles/ShowListItem.css"
import {Star} from "./Star";

export function ShowListItem({show}) {
    let id = show.id
    let title = show.title;
    let titleWithYear = show.title + " (" + show.year + ")";
    let posterUrl = show.posterUrl;
    let rating = show.rating?.toFixed(1) ?? 0.0
    let hrs = show.hrs?.toFixed(1) ?? 0.0

    return (
        <a href={"/show/" + id}>
            <div className="show-list-item" key={"sli" + id}>
                <div className="poster">
                    <img src={posterUrl} alt={title + " poster"}/>
                </div>
                <div className="title">{titleWithYear}</div>
                <div className="score">
                    <span>{rating}<Star type="rating"/> {hrs}<Star type="hrs"/></span>
                </div>
            </div>
        </a>
    );
}
