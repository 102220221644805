import "./styles/App.css";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Layout} from "./pages/Layout";
import {About} from "./pages/About";
import {Show} from "./pages/Show";
import {Home} from "./pages/Home";

export function App() {
    return (
        <div className="App">
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Layout/>}>
                        <Route index element={<Home />} />
                        <Route path="/about" element={<About/>}/>
                        <Route path="/show/:showId" element={<Show/>}/>
                    </Route>
                </Routes>
            </BrowserRouter>
        </div>
    );
}
