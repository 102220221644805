import "../styles/ShowList.css";
import React, {useEffect, useState} from "react";
import {ShowListItem} from "./ShowListItem";

const urlListsPopular = process.env.NODE_ENV === 'development' ?
    "http://localhost:3000/stub" :
    "https://hrs.avecyclop.com/data/lists/popular";

export function ShowList({type}) {
    const [list, setList] = useState("");

    useEffect(() => {
        fetch(`${urlListsPopular}/${type}.json`)
            .then(response => {
                switch (response.status) {
                    case 200:
                        response.json().then(body => {
                            setList(body.map(item => <ShowListItem show={item}/>).slice(0, 9));
                        });
                        break;
                    default:
                        console.log(response.status);
                }
            }, reason => console.log(reason));
    }, [type]);

    return (
        <div className="show-list">
            {list}
        </div>
    );
}
