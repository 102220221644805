import "../styles/SearchBar.css"
import React, {useEffect, useState} from "react";
import {displayElement, hideElement, jsonInit, useSessionStorage} from "../Utils";

const idPattern = /.*(tt\d{1,8}).*/
const nonAlphaNumeric = /"[^A-Za-z0-9 ]"/g

const urlSearch = process.env.NODE_ENV === 'development' ?
    "http://localhost:3000/stub/" :
    "https://hrs.avecyclop.com/data/shows?search=";

export function SearchBar() {
    const [searchResults, setSearchResults] = useState(null);
    const [getSearchTerm, storeSearchTerm, clearSearchTerm] = useSessionStorage("search-bar-search-term");
    const [getSearchResults, storeSearchResults, clearSearchResults] = useSessionStorage("search-bar-results");

    function showResults() {
        let results = getSearchResults();
        if (results != null) {
            let items = JSON.parse(results);
            if (items.length === 0) {
                items.push({year:` No results for "${getSearchTerm()}" `})
            }
            setSearchResults(items.map((item) => (
                <li key={"search-item-" + item.id}>
                    <a href={"/show/" + item.id}>{item.title} ({item.year})</a>
                </li>
            )));
            displayElement("search-results", "block")
        }
    }

    function hideResults() {
        hideElement("search-results");
    }

    function handleSearch(e: KeyboardEvent) {
        if (e.key === "Escape") {
            hideResults();
        }
        if (e.key === "Enter") {
            let searchTerm = document.getElementById("search-input").value;
            if (!searchTerm || searchTerm.length < 5) {
                return;
            }
            let idMatch = idPattern.exec(searchTerm);
            if (idMatch != null) {
                clearSearchTerm();
                window.location.href = `/show/${idMatch[1]}`;
                return;
            }

            storeSearchTerm(searchTerm);
            let sanitisedSearchTerm = searchTerm.replaceAll(nonAlphaNumeric, '');
            storeSearchResults('[{"year":" Searching... "}]');
            showResults();
            fetch(`${urlSearch}${sanitisedSearchTerm}`, jsonInit)
                .then(response => {
                    switch (response.status) {
                        case 200:
                            response.text().then(body => {
                                storeSearchResults(body);
                                showResults();
                            });
                            break;
                        default:
                            storeSearchResults('[{"year":" Something went wrong. "}]');
                            showResults();
                    }
                });
        }
    }

    useEffect(() => {
        document.getElementById("search-input").value = getSearchTerm();
        return () => {
            clearSearchTerm();
            clearSearchResults();
            hideResults();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="search-bar">
            <input id="search-input" type="search" placeholder="ID or title search"
                   onKeyDown={(e) => handleSearch(e)}
                   onFocus={() => showResults()}
            />
            <div id="search-results">
                <ul>{searchResults}</ul>
            </div>
        </div>
    );
}
