import "../styles/About.css"
import {Star} from "../components/Star";
import {ShowFull} from "../components/ShowFull";

const devMode = process.env.NODE_ENV === 'development' ? (<span style={{color: "red"}}> - Development mode</span>) : "";

export function About() {
    return (
        <article className="about">
            <h1>About{devMode}</h1>
            <div className="description">
                <div className="summary">
                    <p>
                        Have you ever looked at a show on IMDb with a rating of 7.6 only to find out it was kind of bad?
                        Does your favorite show only have a rating of 6.4? Do you feel the need to read a handful of
                        reviews before you pick a movie?
                    </p>
                    <p>
                        Helpful Review Score (HRS) is an analysis of what the reviews say about a show and gives it a
                        score based on how many people agreed with those reviews, i.e. how many thought the review was
                        helpful.
                    </p>
                    <p>
                        The number in parenthesis is the amount of votes. Few people take the time to write or read
                        reviews, so the votes to calculate HRS will always be much lower than the rating votes.
                    </p>
                </div>
                <div className="rating">
                    <Star type="rating"/><br/>
                    This score is the regular rating shown on IMDb.
                </div>
                <div className="hrs">
                    <Star type="hrs"/><br/>
                    This score is based on the reviews.
                </div>
            </div>
            <div className="examples">
                <ShowFull showId="tt1517268"/>
                <ShowFull showId="tt15398776"/>
            </div>
        </article>
    );
}
