import "../styles/Home.css"
import {ShowList} from "../components/ShowList";

export function Home() {
    return (
        <article className="home">
            <h2>Movies in the spotlight</h2>
            <ShowList type="movies"/>
            <h2>Series in the spotlight</h2>
            <ShowList type="series"/>
        </article>
    );
}
