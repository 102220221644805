import "../styles/ShowFull.css";
import {ReactComponent as ExtLink} from "../assets/external-link.svg";
import React, {useEffect, useState} from "react";
import {Star} from "./Star";
import {displayElement, hideElement, jsonInit, prettyNumber, prettyYears} from "../Utils";

const urlShows = process.env.NODE_ENV === 'development' ?
    "http://localhost:3000/stub" :
    "https://hrs.avecyclop.com/data/shows";

export function ShowFull({showId, highlights}) {
    const [notice, setNotice] = useState("Getting show...");
    const [title, setTitle] = useState("");
    const [years, setYears] = useState("");
    const [poster, setPoster] = useState("");
    const [rating, setRating] = useState("");
    const [ratingVotes, setRatingVotes] = useState("");
    const [hrs, setHrs] = useState("");
    const [hrsVotes, setHrsVotes] = useState("");
    const [highlighted, setHighlighted] = useState("");

    useEffect(() => {
        hideElement("show" + showId)
        fetch(`${urlShows}/${showId}.json`, jsonInit)
            .then(response => {
                switch (response.status) {
                    case 200:
                        renderShow(response);
                        break;
                    case 202:
                        renderNotice("We don't have that show yet, please come back later.");
                        break;
                    case 404:
                        renderNotice("No such show.");
                        break;
                    default:
                        renderNotice("Something went wrong, please retry at a later time.");
                        break;
                }
            });

        function renderShow(response) {
            response.json().then(json => {
                setTitle(json.title.title);
                setYears(prettyYears(json.title))
                setPoster(json.title.posterUrl);
                setRating(json.title.rating.toFixed(1));
                setRatingVotes(prettyNumber(json.title.votes));
                setHrs(json.reviews.hrs.toFixed(1));
                setHrsVotes(prettyNumber(json.reviews.votes));
                if (highlights) {
                    setHighlighted(json.reviews.highlighted.map((item) => (
                        <p><a href={item.link} target="_blank" rel="noreferrer">{item.title} <ExtLink/></a></p>
                    )));
                    displayElement("highlights" + showId, "block")
                } else {
                    setHighlighted(null);
                    document.getElementById("poster-"+showId).style = "max-width: 250px";
                    hideElement("highlights" + showId);
                }
                displayElement("show" + showId, "grid")
                hideElement("notice" + showId)
            });
        }

        function renderNotice(text) {
            setNotice(text)
            displayElement("notice" + showId, "block")
        }
    }, [showId, highlights]);

    return (
        <>
            <div id={"notice" + showId}>{notice}</div>
            <article id={"show" + showId} className="show">
                <div className="poster">
                    <img id={"poster-"+showId} src={poster} alt={title + " poster"}/>
                </div>
                <div className="title">
                    <h3>{title + " " + years}</h3>
                </div>
                <div className="rating">
                    <Star type="rating"/><br/>
                    {rating}<br/>
                    ({ratingVotes})
                </div>
                <div className="hrs">
                    <Star type="hrs"/><br/>
                    {hrs}<br/>
                    ({hrsVotes})
                </div>
                <div id={"highlights" + showId} className="reviews">
                    {highlighted}
                </div>
            </article>
        </>
    );
}
